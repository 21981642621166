<div class="card-column">
  <div class="menu-card">
  <a routerLink='/item/{{item?.itemInfo.id}}'>
    <div class="img"><img [src]="item?.itemInfo?.mainPic || 'assets/images/logo.png'" alt=""></div>
    <div class="card-title f-s-18 text-center">{{item?.itemInfo?.title | iknLocalized}}</div>
  </a>
  <div class="row-nums">
    <div class="desc text-danger out-stock-txt respect-dir" *ngIf="checkOutOfStock(item)">
      {{'Out of Stock'| translate}}
    </div>
    <div class="force-rtl" style="display:flex;" *ngIf="!checkOutOfStock(item)">
      <span class="bg-primary plus-icon" (click)="addItem(item)"><i class="im im-plus"></i></span>
      <span class="num-qty respect-dir">
        <span class="card-price font-bold">{{item.itemInfo.price | iknCurrency}} {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}</span>
        <span class="card-qty">&nbsp;<i class="fa fa-times"></i> {{item.qty}}</span>
      </span>
      <span class="bg-primary minus-icon" (click)="removeItem(item)"><i class="im im-minus"></i></span>
    </div>
  </div>
</div>
</div>